/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  $('#mobile-menu-toggle').click(function() {
    $('#mobile-menu').css('right','0');
  });
  $('#close-mobile-menu').click(function(e) {
    e.preventDefault();
    $('#mobile-menu').css('right','-320px');
  });

  $(function(){
  $(".dropdown").hover(
    function() {
      $('.dropdown-menu', this).stop( true, true ).slideToggle();
      $(this).toggleClass('open');
    },
    function() {
      $('.dropdown-menu', this).stop( true, true ).slideToggle();
      $(this).toggleClass('open');
    });
  });


$( "#menu-principal li" ).click(function(){
  $( "#menu-principal li" ).removeClass('open');
});

  //Mobile nav scroll up hide/show
  var delta, didScroll, hasScrolled, lastScrollTop, navbarHeight;
  didScroll = false;
  delta = 5;
  lastScrollTop = 0;
  navbarHeight = $('#mobile-header').outerHeight();
  hasScrolled = function() {
    var st;
    st = $(this).scrollTop();
    if (Math.abs(lastScrollTop - st) <= delta) {
      return;
    }
    if (st > lastScrollTop && st > navbarHeight) {
      $("#mobile-header").addClass('nav-up');
    } else {
      $("#mobile-header").removeClass('nav-up');
    }
    lastScrollTop = st;
  };
  $(window).scroll(function(event) {
    didScroll = true;
  });
  setInterval((function() {
    if (didScroll) {
      hasScrolled();
      didScroll = false;
    }
  }), 250);
    //Search overlay
  $(".toggleSearch").click(function(){
    $(".search-overlay").addClass('active');
    setTimeout(function(){
      $("#overlay-search-input").focus();
    },100);

  });
  $("#overlay-search-input").blur(function(){
    $(".search-overlay").removeClass('active');
  });


// //Si aprieto en el content remuevo la clase activo
//  $(".search-overlay").on("click",function(e) {
                    
//          var container = $("#content");
                            
//             if (!container.is(e.target) && container.has(e.target).length === 0) { 
//                $(".search-overlay").removeClass('active');
//             }
//   });

  $(".search-overlay .cancelSearch").click(function(){
    $(".search-overlay").removeClass('active');
  });
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
